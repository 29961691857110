import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Translation, getI18n } from 'react-i18next';
import { Affix, Card, Col, Form, Row, Spin, Input, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import FormMetadata from '../../../elements/components/FormMetadata';
import { pathTo } from '../../../Routes';
import Logger from '../../../../../lib/Logger';
import { Colorpicker } from 'antd-colorpicker';
import UserInput from '../../users/containers/UserInputContainer';
import PlanInput from '../../plans/containers/PlanInputContainer';

const PartnershipForm = ({
  id,
  data,
  errors,
  load,
  destroyForm,
  isLoading,
  isSubmitting,
  created_id,
  ...props
}) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [form] = Form.useForm();

  const accessOptions = [
    'partnership_account',
    'register_code',
    'journal_partner_tags',
    'user_roles',
    'calendar',
    'resources',
    'care_guides',
    'care_guide_partner_tags',
  ];

  const accessCareGuideOptions = [
    'member_profile',
    'add_resource',
    'schedule_event',
    'journal_post',
    'care_guide',
    'calendar',
    'care_team',
    'resources',
  ];

  const accessSectionOptions = ['members', 'administrator', 'messages'];

  // form column settings
  const layout = {
    main: {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    },
  };

  // load record data from API
  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  // update input values when new data is available
  const dataString = JSON.stringify(data);

  useEffect(() => {
    if (id && !isSubmitting) {
      let dataObj = JSON.parse(dataString);
      form.setFieldsValue({
        ...dataObj,
        color_hex: { hex: dataObj.color_hex },
      });
    }
  }, [form, dataString, isSubmitting, id]);

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in errors) {
      form.setFields([{ name: field, errors: errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, errors]);

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PartnershipEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `PartnershipForm.submitData(###)`);

    // API POST/PUT payload
    let payload = {};
    for (const input of Object.keys(data)) {
      if (['color_hex'].includes(input)) {
        payload[input] = values.color_hex ? values.color_hex.hex : 'FFFFFF';
      } else if (['plan'].includes(input)) {
        payload[input + '_id'] = parseInt(values[input]);
      } else if (['profile'].includes(input)) {
        if (values[input] === '') {
          payload['user_' + input + '_id'] = null;
        } else {
          payload['user_' + input + '_id'] = values[input];
        }
      } else if (['access_role_admin_in_app'].includes(input)) {
        const accessValues = {};
        for (const access of accessOptions) {
          if (values[input]) {
            accessValues[access] = values[input].includes(access);
          }
        }
        payload[input] = accessValues;
      } else if (['access_role_admin_in_care_guide'].includes(input)) {
        const accessCareGuideValues = {};
        for (const access of accessCareGuideOptions) {
          if (values[input]) {
            accessCareGuideValues[access] = values[input].includes(access);
          }
        }
        payload[input] = accessCareGuideValues;
      } else if (['access_tabs_in_app'].includes(input)) {
        const accessSectionValues = {};
        for (const access of accessSectionOptions) {
          if (values[input]) {
            accessSectionValues[access] = values[input].includes(access);
          }
        }
        payload[input] = accessSectionValues;
      } else if (['hide_partner_tags'].includes(input)) {
        payload[input] = values[input] ? true : false;
      } else if (input in values) {
        payload[input] = values[input];
      } else if (['cognito_form_id', 'cognito_form_key'].includes(input)) {
        payload[input] = values[input] ? values[input] : null;
      }
    }

    if (id) {
      // update
      props.update(id, payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    } else {
      // create
      props.create(payload, (success) => {
        if (success) {
          message.success(getI18n().t('feedback_form_success'));
        } else {
          message.error(getI18n().t('feedback_form_error'));
        }
      });
    }
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `PartnershipForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `PartnershipForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  // redirect add form to edit form on successful create action
  useEffect(() => {
    if (created_id) {
      setRedirectTo(pathTo('PartnershipEditScreen', { id: created_id }));
    }
    return () => {
      destroyForm();
    };
  }, [created_id, setRedirectTo, destroyForm]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <div className="partnership-form">
            <Form
              name="partnership_form"
              form={form}
              initialValues={data}
              onFinish={handleFinish}
              onFinishFailed={handleFinishFailed}
              validateTrigger="onSubmit"
              {...layout.main}
            >
              <Row gutter={16}>
                <Col xs={24} lg={18}>
                  <Card
                    title={
                      id
                        ? t('partnership_edit_title')
                        : t('partnership_add_title')
                    }
                    extra={
                      isLoading ? (
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 20 }} spin />
                          }
                        />
                      ) : null
                    }
                  >
                    {id ? (
                      <div className="form-group">
                        <Form.Item label={t('partnership_partnership_id')}>
                          <span className="ant-form-text">{id}</span>
                        </Form.Item>
                      </div>
                    ) : null}

                    <div className="form-group">
                      <Form.Item
                        name="name"
                        label={t('partnership_account_form_input_name')}
                        rules={[
                          {
                            required: true,
                            message: t('feedback_validation_required'),
                          },
                          {
                            min: 2,
                            max: 20,
                            message: t('feedback_validation_length_input', {
                              min: 2,
                              max: 20,
                            }),
                          },
                          {
                            pattern: /^\w+$/,
                            message: t('feedback_validation_alphanumeric'),
                          },
                          {
                            pattern: /(?!^\d+$)^.+$/,
                            message: t('feedback_validation_not_number'),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <UserInput
                      name="profile"
                      label={t('user_profiles_partnership_profile')}
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      disabled={isLoading || isSubmitting}
                    />
                    <PlanInput
                      name="plan"
                      rules={[
                        {
                          required: true,
                          message: t('feedback_validation_required'),
                        },
                      ]}
                      form={form}
                      label={t('registration_code_plan')}
                      disabled={isLoading || isSubmitting}
                    />

                    <div className="form-group">
                      <Form.Item
                        name="cognito_form_key"
                        label={t('partnership_account_cognito_form_key')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 40,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 40,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <Form.Item
                        name="cognito_form_id"
                        label={t('partnership_account_cognito_form_id')}
                        rules={[
                          {
                            type: 'string',
                            min: 0,
                            max: 10,
                            message: t('feedback_validation_char_range', {
                              min: 0,
                              max: 10,
                            }),
                          },
                        ]}
                      >
                        <Input disabled={isLoading || isSubmitting} />
                      </Form.Item>
                    </div>

                    <div className="form-group">
                      <Form.Item
                        name={`color_hex`}
                        label={t('partnership_account_form_input_color')}
                      >
                        <Colorpicker popup />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <Form.Item
                        name="hide_partner_tags"
                        valuePropName="checked"
                        label={t('partnership_account_form_input_hide_tag')}
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </div>
                  </Card>

                  <Card title={t('partnership_header_access_admin')}>
                    <div className="form-group checkbox-list">
                      <Form.Item
                        name="access_role_admin_in_app"
                        label={t('partnership_access_admin_in_app')}
                      >
                        <Checkbox.Group
                          options={accessOptions.map((x) => {
                            return {
                              value: x,
                              label: t('partnership_accounts_access_' + x),
                            };
                          })}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group checkbox-list">
                      <Form.Item
                        name="access_role_admin_in_care_guide"
                        label={t('partnership_access_admin_in_care_guide')}
                      >
                        <Checkbox.Group
                          options={accessCareGuideOptions.map((x) => {
                            return {
                              value: x,
                              label: t(
                                'partnership_accounts_access_care_guide_' + x
                              ),
                            };
                          })}
                        />
                      </Form.Item>
                    </div>

                    <div className="form-group checkbox-list">
                      <Form.Item
                        name="access_tabs_in_app"
                        label={t('partnership_access_tabs_in_app')}
                      >
                        <Checkbox.Group
                          options={accessSectionOptions.map((x) => {
                            return {
                              value: x,
                              label: t('partnership_accounts_access_tab_' + x),
                            };
                          })}
                        />
                      </Form.Item>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} lg={6}>
                  <Affix offsetTop={10}>
                    <Card title={t('form_metadata_header')}>
                      <FormMetadata
                        id={id}
                        isSubmitting={isSubmitting}
                        delete={props.delete.bind(this)}
                        deleteRedirectTo="PartnershipsScreen"
                        createdAt={props.createdAt}
                        updatedAt={props.updatedAt}
                      />
                    </Card>
                  </Affix>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Translation>
  );
};

export default PartnershipForm;

Logger.log('silly', `PartnershipForm loaded.`);
