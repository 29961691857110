import axios from 'axios';
import { Map } from 'immutable';

import { sessionPasswordExpired } from './actions';
import store from './store';
import Auth from '../lib/Auth';
import QueryString from '../lib/QueryString';
import Logger from '../lib/Logger';
import Config from '../Config';

class API {
  constructor(config, axios) {
    Logger.log('debug', `API.constructor(###, ###)`);

    // config props
    this.environment = config.get('ENVIRONMENT');
    this.base_url = config.get('API_BASE_URL');
    this.app_key = config.get('API_APP_KEY');

    // internal props
    this.axios = axios;
    this.authToken = null;
    this.authExpires = null;
    this.destroySession = null;
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  setAuthExpires(authExpires) {
    this.authExpires = authExpires;
  }

  setDestroySession(destroySession) {
    this.destroySession = destroySession;
  }

  // destroy session data and redirect to auth screen
  _logout = () => {
    Logger.log('debug', `API._logout()`);
    this.destroySession();
  };

  // get authentication token
  _getAuthToken() {
    Logger.log('debug', `API._getAuthToken()`);
    const authToken = this.authToken;
    const authExpires = this.authExpires;
    return Auth.isAuthTokenValid(authToken, authExpires) ? authToken : false;
  }

  // get client's timezone
  _getClientTimezone() {
    return window.hasOwnProperty('Intl') &&
      Intl.DateTimeFormat &&
      Intl.DateTimeFormat().resolvedOptions
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : null;
  }

  // get request headers
  _getRequestHeaders(validateStatus = null, authenticate = true) {
    Logger.log(
      'debug',
      `API._getRequestHeaders(${validateStatus}, ${authenticate})`
    );

    const authToken = this._getAuthToken();
    if (authenticate && !authToken) {
      this._logout();
      return false;
    }
    const headers = {};
    if (authToken) {
      headers['Authorization'] = `Bearer ${authToken}`;
    }
    const vaildateStatusConfig = validateStatus
      ? { validateStatus: (status) => status < validateStatus }
      : {};
    const tz = this._getClientTimezone();
    if (tz) {
      headers['X-Client-Timezone'] = tz;
    }
    return { ...vaildateStatusConfig, ...{ headers: headers } };
  }

  // get full URI for an API endpoint
  _getUri(path) {
    Logger.log('debug', `API._getUri(${path})`);
    return (
      this.base_url +
      path +
      (path.includes('?') ? '&' : '?') +
      'app_key=' +
      this.app_key
    );
  }

  // generic API call helper
  async _callApi(method, path, payload = null, authenticate = true) {
    Logger.log(
      'debug',
      `API._callApi(###, ${path}, ###, ${authenticate})`,
      payload
    );

    // get headers and check authentication token
    const requestHeaders = this._getRequestHeaders(500, authenticate);
    if (false === requestHeaders) {
      return Map({ status: 401, data: { error: `Not Authenticated` } });
    }

    const self = this;
    if (payload) {
      // call API with payload
      return await method(path, payload, requestHeaders)
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    } else {
      // call API without payload
      return await method(path, requestHeaders)
        .then(async function (response) {
          Logger.log('verbose', `API Response: %j`, response);
          if (response.status === 401) {
            self._logout();
          } else if (response.status === 403 && response.data.update_password) {
            store.dispatch(sessionPasswordExpired());
          }
          return Map(response);
        })
        .catch(function (error) {
          Logger.log('error', `${error}`);
          return Map({ status: 500, data: { error: `${error}` } });
        });
    }
  }

  // GET method
  async GET(path, authenticate = true) {
    Logger.log('debug', `API.GET(${path}, ${authenticate})`);
    return await this._callApi(this.axios.get, path, null, authenticate);
  }

  // POST method
  async POST(path, payload, authenticate = true) {
    Logger.log('debug', `API.POST(${path}, %j, ${authenticate})`, payload);
    return await this._callApi(this.axios.post, path, payload, authenticate);
  }

  // PUT method
  async PUT(path, payload, authenticate = true) {
    Logger.log('debug', `API.PUT(${path}, %j, ${authenticate})`, payload);
    return await this._callApi(this.axios.put, path, payload, authenticate);
  }

  // PATCH method
  async PATCH(path, payload, authenticate = true) {
    Logger.log('debug', `API.PUT(${path}, %j, ${authenticate})`, payload);
    return await this._callApi(this.axios.patch, path, payload, authenticate);
  }

  // DELETE method
  async DELETE(path, authenticate = true) {
    Logger.log('debug', `API.DELETE(${path}, ${authenticate})`);
    return await this._callApi(this.axios.delete, path, null, authenticate);
  }

  // GET /token
  async getToken(username, password, token = null, otp = null) {
    Logger.log('debug', `API.getToken(###, ###, ###, ###`);

    // prep credentials header config
    const authConfig = {
      validateStatus: function (status) {
        return status < 500;
      },
    };

    // use basic auth or bearer token (with optional one-time password)
    if (token === null) {
      authConfig['auth'] = {
        username: username,
        password: password,
      };
    } else {
      authConfig['headers'] = {};
      if (otp !== null) {
        authConfig['headers']['Authorization'] = `TOTP ${token} ${otp}`;
      } else {
        authConfig['headers']['Authorization'] = `Bearer ${token}`;
      }
    }

    // call API
    const output = await this.axios
      .get(this._getUri(`/token`), authConfig)
      .then(async function (response) {
        Logger.log('verbose', 'API Response: %j', response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });

    return output;
  }

  // GET /user_account
  async getUserAccount() {
    Logger.log('debug', `API.getUserAccount()`);
    return await this.GET(this._getUri(`/user_account`));
  }

  // PUT /user_account
  async putUserAccount(payload) {
    Logger.log('debug', `API.putUserAccount(%j)`, payload);
    return await this.PUT(this._getUri(`/user_account`), payload);
  }

  // PUT /user_account/password
  async putAccountPassword(payload) {
    Logger.log('debug', `API.putAccountPassword(%j)`, payload);
    return await this.PUT(this._getUri(`/user_account/password`), payload);
  }

  // PUT /user_account/2fa
  async putAccount2FA(payload) {
    Logger.log('debug', `API.putAccount2FA(%j)`, payload);
    return await this.PUT(this._getUri(`/user_account/2fa`), payload);
  }

  // GET /categories
  async getCategories(page = 1, limit = 10) {
    Logger.log('debug', `API.getCategories(${page}, ${limit})`);
    return await this.GET(
      this._getUri(`/categories/${parseInt(page)}/${parseInt(limit)}`)
    );
  }

  // GET /roles
  async getRoles(
    page = 1,
    limit = 10,
    order = null,
    filter = null,
    type = null
  ) {
    Logger.log(
      'debug',
      `API.getRoles(${page}, ${limit}, ${order}, %j, ${type})`,
      filter
    );
    if (type) {
      return await this.GET(
        this._getUri(
          QueryString.append(
            `/roles/${type}/${parseInt(page)}/${parseInt(limit)}`,
            { order_by: order, ...filter }
          )
        )
      );
    } else {
      return await this.GET(
        this._getUri(
          QueryString.append(`/roles/${parseInt(page)}/${parseInt(limit)}`, {
            order_by: order,
            ...filter,
          })
        )
      );
    }
  }

  // GET /role/{id}
  async getRole(id) {
    Logger.log('debug', `API.getRole(${id})`);
    return await this.GET(this._getUri(`/role/${parseInt(id)}`));
  }

  // POST /roles
  async postRoles(payload) {
    Logger.log('debug', `API.postRoles(%j)`, payload);
    return await this.POST(this._getUri(`/roles`), payload);
  }

  // PUT /role/{id}
  async putRole(id, payload) {
    Logger.log('debug', `API.putRole(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/role/${parseInt(id)}`), payload);
  }

  // DELETE /role/{id}
  async deleteRole(id) {
    Logger.log('debug', `API.deleteRole(${id})`);
    return await this.DELETE(this._getUri(`/role/${parseInt(id)}`));
  }

  // GET /roles/csv
  async getRolesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getRolesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/roles/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /countries
  async getCountries(page = 1, limit = 250, order = null, filter = null) {
    Logger.log('debug', `API.getCountries(${page}, ${limit})`);
    // return await this.GET(this._getUri(`/countries/${parseInt(page)}/${parseInt(limit)}`, {order_by: order, ...filter})));
    return await this.GET(
      this._getUri(
        QueryString.append(`/countries/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /country/{id}
  async getCountry(id) {
    Logger.log('debug', `API.getCountry(${id})`);
    return await this.GET(this._getUri(`/country/${id}`));
  }

  // POST /country
  async postCountry(payload) {
    Logger.log('debug', `API.postCountry(%j)`, payload);
    return await this.POST(this._getUri(`/country`), payload);
  }

  // PUT /country/{id}
  async putCountry(id, payload) {
    Logger.log('debug', `API.putCountry(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/country/${id}`), payload);
  }

  // DELETE /country/{id}
  async deleteCountry(id) {
    Logger.log('debug', `API.deleteCountry(${id})`);
    return await this.DELETE(this._getUri(`/country/${id}`));
  }

  // GET /regions
  async getRegions(page = 1, limit = 100, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getRegions(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/regions/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /region/{id}
  async getRegion(id) {
    Logger.log('debug', `API.getRegion(${id})`);
    return await this.GET(this._getUri(`/region/${id}`));
  }

  // POST /region
  async postRegion(payload) {
    Logger.log('debug', `API.postRegion(%j)`, payload);
    return await this.POST(this._getUri(`/region`), payload);
  }

  // PUT /region/{id}
  async putRegion(id, payload) {
    Logger.log('debug', `API.putRegion(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/region/${id}`), payload);
  }

  // DELETE /country/{id}
  async deleteRegion(id) {
    Logger.log('debug', `API.deleteRegion(${id})`);
    return await this.DELETE(this._getUri(`/region/${id}`));
  }

  // GET /subregions
  async getSubRegions(page = 1, limit = 100, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getSubregions(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/subregions/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /Subregion/{id}
  async getSubRegion(id) {
    Logger.log('debug', `API.getSubRegion(${id})`);
    return await this.GET(this._getUri(`/subregion/${id}`));
  }

  // POST /Subregion
  async postSubRegion(payload) {
    Logger.log('debug', `API.postSubRegion(%j)`, payload);
    return await this.POST(this._getUri(`/subregion`), payload);
  }

  // PUT /Subregion/{id}
  async putSubRegion(id, payload) {
    Logger.log('debug', `API.putSubRegion(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/subregion/${id}`), payload);
  }

  // DELETE /subregion/{id}
  async deleteSubRegion(id) {
    Logger.log('debug', `API.deleteSubRegion(${id})`);
    return await this.DELETE(this._getUri(`/subregion/${id}`));
  }

  // GET /cities
  async getCities(page = 1, limit = 100, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCities(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/cities/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /City/{id}
  async getCity(id) {
    Logger.log('debug', `API.getCity(${id})`);
    return await this.GET(this._getUri(`/city/${id}`));
  }

  // POST /City
  async postCity(payload) {
    Logger.log('debug', `API.postCity(%j)`, payload);
    return await this.POST(this._getUri(`/city`), payload);
  }

  // PUT /City/{id}
  async putCity(id, payload) {
    Logger.log('debug', `API.putCity(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/city/${id}`), payload);
  }

  // DELETE /City/{id}
  async deleteCity(id) {
    Logger.log('debug', `API.deleteCity(${id})`);
    return await this.DELETE(this._getUri(`/city/${id}`));
  }

  // GET /postal_codes
  async getPostalCodes(page = 1, limit = 100, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPostalCodes(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/postal_codes/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /PostalCode/{id}
  async getPostalCode(id) {
    Logger.log('debug', `API.getPostalCode(${id})`);
    return await this.GET(this._getUri(`/postal_code/${id}`));
  }

  // POST /PostalCode
  async postPostalCode(payload) {
    Logger.log('debug', `API.postPostalCode(%j)`, payload);
    return await this.POST(this._getUri(`/postal_code`), payload);
  }

  // PUT /PostalCode/{id}
  async putPostalCode(id, payload) {
    Logger.log('debug', `API.putPostalCode(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/postal_code/${id}`), payload);
  }

  // DELETE /PostalCode/{id}
  async deletePostalCode(id) {
    Logger.log('debug', `API.deletePostalCode(${id})`);
    return await this.DELETE(this._getUri(`/postal_code/${id}`));
  }

  // GET /users
  async getUsers(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getUsers(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/users/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /users/partnership/{id}
  async getUsersPartnership(
    partnershipId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUsersPartnership(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/users/partnership/${partnershipId}/${parseInt(page)}/${parseInt(
            limit
          )}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /users/care_guide/{id}
  async getUsersPartnerPartnership(
    partnershipId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUsersPartnerPartnership(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/users/partnership/${partnershipId}/partner/${parseInt(
            page
          )}/${parseInt(limit)}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /users/care_guide/{id}
  async getUsersCareGuide(
    careGuideId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUsersCareGuide(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/users/care_guide/${careGuideId}/${parseInt(page)}/${parseInt(
            limit
          )}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /users/care_guide/{id}
  async getUsersCareGuides(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getUsersCareGuides(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/users/care_guides/${parseInt(page)}/${parseInt(limit)}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /users/care_guide/{id}
  async getUsersCareGuidePartnership(
    careGuideId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUsersCareGuidePartnership(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/users/care_guide/${careGuideId}/partner/${parseInt(
            page
          )}/${parseInt(limit)}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /user/{id}
  async getUser(id) {
    Logger.log('debug', `API.getUser(${id})`);
    return await this.GET(this._getUri(`/user/${parseInt(id)}`));
  }

  // POST /users
  async postUsers(payload) {
    Logger.log('debug', `API.postUsers(%j)`, payload);
    return await this.POST(this._getUri(`/users`), payload);
  }

  // PUT /user/{id}
  async putUser(id, payload) {
    Logger.log('debug', `API.putUser(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/user/${parseInt(id)}`), payload);
  }

  // DELETE /user/{id}
  async deleteUser(id) {
    Logger.log('debug', `API.deleteUser(${id})`);
    return await this.DELETE(this._getUri(`/user/${parseInt(id)}`));
  }

  // GET /users/csv
  async getUsersCSV(order = null, filter = null) {
    Logger.log('debug', `API.getUsersCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/users/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /administrators
  async getAdministrators(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getAdministrators(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/administrators/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /administrator/{id}
  async getAdministrator(id) {
    Logger.log('debug', `API.getAdministrator(${id})`);
    return await this.GET(this._getUri(`/administrator/${parseInt(id)}`));
  }

  // POST /administrators
  async postAdministrators(payload) {
    Logger.log('debug', `API.postAdministrators(%j)`, payload);
    return await this.POST(this._getUri(`/administrators`), payload);
  }

  // PUT /administrator/{id}
  async putAdministrator(id, payload) {
    Logger.log('debug', `API.putAdministrator(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/administrator/${parseInt(id)}`),
      payload
    );
  }

  // DELETE /administrator/{id}
  async deleteAdministrator(id) {
    Logger.log('debug', `API.deleteAdministrator(${id})`);
    return await this.DELETE(this._getUri(`/administrator/${parseInt(id)}`));
  }

  // GET /administrators/csv
  async getAdministratorsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getAdministratorsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/administrators/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /app_keys
  async getAppKeys(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getAppKeys(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/app_keys/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /app_key/{id}
  async getAppKey(id) {
    Logger.log('debug', `API.getAppKey(${id})`);
    return await this.GET(this._getUri(`/app_key/${parseInt(id)}`));
  }

  // POST /app_keys
  async postAppKeys(payload) {
    Logger.log('debug', `API.postAppKeys(%j)`, payload);
    return await this.POST(this._getUri(`/app_keys`), payload);
  }

  // PUT /app_key/{id}
  async putAppKey(id, payload) {
    Logger.log('debug', `API.putAppKey(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/app_key/${parseInt(id)}`), payload);
  }

  // DELETE /app_key/{id}
  async deleteAppKey(id) {
    Logger.log('debug', `API.deleteAppKey(${id})`);
    return await this.DELETE(this._getUri(`/app_key/${parseInt(id)}`));
  }

  // GET /terms_of_services
  async getTermsOfServices(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getTermsOfServices(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/terms_of_services/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /terms_of_service/{id}
  async getTermsOfService(id) {
    Logger.log('debug', `API.getTermsOfService(${id})`);
    return await this.GET(this._getUri(`/terms_of_service/${parseInt(id)}`));
  }

  // POST /terms_of_services
  async postTermsOfServices(payload) {
    Logger.log('debug', `API.postTermsOfServices(%j)`, payload);
    return await this.POST(this._getUri(`/terms_of_services`), payload);
  }

  // PUT /terms_of_service/{id}
  async putTermsOfService(id, payload) {
    Logger.log('debug', `API.putTermsOfService(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/terms_of_service/${parseInt(id)}`),
      payload
    );
  }

  // DELETE /terms_of_service/{id}
  async deleteTermsOfService(id) {
    Logger.log('debug', `API.deleteTermsOfService(${id})`);
    return await this.DELETE(this._getUri(`/terms_of_service/${parseInt(id)}`));
  }

  // GET /terms_of_services/csv
  async getTermsOfServicesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getTermsOfServicesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/terms_of_services/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /logins
  async getLogins(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getLogins(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/logins/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /plans
  async getPlans(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPlans(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/plans/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /plan/{id}
  async getPlan(id) {
    Logger.log('debug', `API.getPlan(${id})`);
    return await this.GET(this._getUri(`/plan/${parseInt(id)}`));
  }

  // POST /plans
  async postPlans(payload) {
    Logger.log('debug', `API.postPlans(%j)`, payload);
    return await this.POST(this._getUri(`/plans`), payload);
  }

  // PUT /plan/{id}
  async putPlan(id, payload) {
    Logger.log('debug', `API.putPlan(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/plan/${parseInt(id)}`), payload);
  }

  // DELETE /plan/{id}
  async deletePlan(id) {
    Logger.log('debug', `API.deletePlan(${id})`);
    return await this.DELETE(this._getUri(`/plan/${parseInt(id)}`));
  }

  // GET /plans/csv
  async getPlansCSV(order = null, filter = null) {
    Logger.log('debug', `API.getPlansCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/plans/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /relationships
  async getRelationships(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getRelationships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/relationships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /relationship/{id}
  async getRelationship(id) {
    Logger.log('debug', `API.getRelationship(${id})`);
    return await this.GET(this._getUri(`/relationship/${parseInt(id)}`));
  }

  // POST /relationships
  async postRelationships(payload) {
    Logger.log('debug', `API.postRelationships(%j)`, payload);
    return await this.POST(this._getUri(`/relationships`), payload);
  }

  // PUT /relationship/{id}
  async putRelationship(id, payload) {
    Logger.log('debug', `API.putRelationship(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/relationship/${parseInt(id)}`),
      payload
    );
  }

  // DELETE /relationship/{id}
  async deleteRelationship(id) {
    Logger.log('debug', `API.deleteRelationship(${id})`);
    return await this.DELETE(this._getUri(`/relationship/${parseInt(id)}`));
  }

  // GET /relationships/csv
  async getRelationshipsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getRelationshipsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/relationships/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /accounts
  async getAccounts(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getAccounts(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/accounts/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /account/{id}
  async getAccount(id) {
    Logger.log('debug', `API.getAccount(${id})`);
    return await this.GET(this._getUri(`/account/${id}`));
  }

  // POST /accounts
  async postAccounts(payload) {
    Logger.log('debug', `API.postAccounts(%j)`, payload);
    return await this.POST(this._getUri(`/accounts`), payload);
  }

  // PUT /account/{id}
  async putAccount(id, payload) {
    Logger.log('debug', `API.putAccount(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/account/${id}`), payload);
  }

  // DELETE /account/{id}
  async deleteAccount(id) {
    Logger.log('debug', `API.deleteAccount(${id})`);
    return await this.DELETE(this._getUri(`/account/${id}`));
  }

  // GET /accounts/csv
  async getAccountsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getAccountsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/accounts/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guides
  async getCareGuides(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCareGuides(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guides/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide/{id}
  async getCareGuide(id) {
    Logger.log('debug', `API.getCareGuide(${id})`);
    return await this.GET(this._getUri(`/care-guide/${id}`));
  }

  // POST /care-guides
  async postCareGuides(payload) {
    Logger.log('debug', `API.postCareGuides(%j)`, payload);
    return await this.POST(this._getUri(`/care-guides`), payload);
  }

  // PUT /care-guide/{id}
  async putCareGuide(id, payload) {
    Logger.log('debug', `API.putCareGuide(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide/${id}`), payload);
  }

  // DELETE /care-guide/{id}
  async deleteCareGuide(id) {
    Logger.log('debug', `API.deleteCareGuide(${id})`);
    return await this.DELETE(this._getUri(`/care-guide/${id}`));
  }

  // PATCH /care-guide/{id}
  async patchSendCsv(payload) {
    Logger.log('debug', `API.patchSendCsv(%j)`, payload);
    return await this.PATCH(this._getUri(`/care-guides/csv`), payload);
  }

  // GET /care-guides/csv
  async getCareGuidesCSV(order = null, filter = null, partnershipId = null) {
    Logger.log('debug', `API.getCareGuidesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guides/csv`, {
          order_by: order,
          partnership_id: partnershipId,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-owners
  async getCareGuideOwners(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCareGuideOwners(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-owners/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-owner/{id}
  async getCareGuideOwner(id) {
    Logger.log('debug', `API.getCareGuideOwner(${id})`);
    return await this.GET(this._getUri(`/care-guide-owner/${id}`));
  }

  // POST /care-guide-owners
  async postCareGuideOwners(payload) {
    Logger.log('debug', `API.postCareGuideOwners(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-owners`), payload);
  }

  // PUT /care-guide-owner/{id}
  async putCareGuideOwner(id, payload) {
    Logger.log('debug', `API.putCareGuideOwner(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-owner/${id}`), payload);
  }

  // DELETE /care-guide-owner/{id}
  async deleteCareGuideOwner(id) {
    Logger.log('debug', `API.deleteCareGuideOwner(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-owner/${id}`));
  }

  // GET /care-guide-owners/csv
  async getCareGuideOwnersCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideOwnersCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-owners/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-templates
  async getCareGuideTemplates(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideTemplates(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-templates/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-template/{id}
  async getCareGuideTemplate(id) {
    Logger.log('debug', `API.getCareGuideTemplate(${id})`);
    return await this.GET(this._getUri(`/care-guide-template/${id}`));
  }

  // POST /care-guide-templates
  async postCareGuideTemplates(payload) {
    Logger.log('debug', `API.postCareGuideTemplates(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-templates`), payload);
  }

  // PUT /care-guide-template/{id}
  async putCareGuideTemplate(id, payload) {
    Logger.log('debug', `API.putCareGuideTemplate(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-template/${id}`), payload);
  }

  // DELETE /care-guide-template/{id}
  async deleteCareGuideTemplate(id) {
    Logger.log('debug', `API.deleteCareGuideTemplate(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-template/${id}`));
  }

  // GET /care-guide-templates/csv
  async getCareGuideTemplatesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideTemplatesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-templates/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-sections
  async getCareGuideSections(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideSections(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-sections/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-section/{id}
  async getCareGuideSection(id) {
    Logger.log('debug', `API.getCareGuideSection(${id})`);
    return await this.GET(this._getUri(`/care-guide-section/${id}`));
  }

  // POST /care-guide-sections
  async postCareGuideSections(payload) {
    Logger.log('debug', `API.postCareGuideSections(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-sections`), payload);
  }

  // PUT /care-guide-section/{id}
  async putCareGuideSection(id, payload) {
    Logger.log('debug', `API.putCareGuideSection(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-section/${id}`), payload);
  }

  // DELETE /care-guide-section/{id}
  async deleteCareGuideSection(id) {
    Logger.log('debug', `API.deleteCareGuideSection(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-section/${id}`));
  }

  // GET /care-guide-sections/csv
  async getCareGuideSectionsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideSectionsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-sections/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-section-fields
  async getCareGuideSectionFields(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideSectionFields(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-section-fields/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-section-field/{id}
  async getCareGuideSectionField(id) {
    Logger.log('debug', `API.getCareGuideSectionField(${id})`);
    return await this.GET(this._getUri(`/care-guide-section-field/${id}`));
  }

  // POST /care-guide-section-fields
  async postCareGuideSectionFields(payload) {
    Logger.log('debug', `API.postCareGuideSectionFields(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-section-fields`), payload);
  }

  // PUT /care-guide-section-field/{id}
  async putCareGuideSectionField(id, payload) {
    Logger.log('debug', `API.putCareGuideSectionField(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-section-field/${id}`),
      payload
    );
  }

  // DELETE /care-guide-section-field/{id}
  async deleteCareGuideSectionField(id) {
    Logger.log('debug', `API.deleteCareGuideSectionField(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-section-field/${id}`));
  }

  // GET /care-guide-helpers
  async getCareGuideHelpers(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCareGuideHelpers(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-helpers/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-helper/{id}
  async getCareGuideHelper(id) {
    Logger.log('debug', `API.getCareGuideHelper(${id})`);
    return await this.GET(this._getUri(`/care-guide-helper/${id}`));
  }

  // POST /care-guide-helpers
  async postCareGuideHelpers(payload) {
    Logger.log('debug', `API.postCareGuideHelpers(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-helpers`), payload);
  }

  // PUT /care-guide-helper/{id}
  async putCareGuideHelper(id, payload) {
    Logger.log('debug', `API.putCareGuideHelper(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-helper/${id}`), payload);
  }

  // DELETE /care-guide-helper/{id}
  async deleteCareGuideHelper(id) {
    Logger.log('debug', `API.deleteCareGuideHelper(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-helper/${id}`));
  }

  // GET /care-guide-helpers/csv
  async getCareGuideHelpersCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideHelpersCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-helpers/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-helper-tags
  async getCareGuideHelperTags(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideHelperTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-helper-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-helper-tag/{id}
  async getCareGuideHelperTag(id) {
    Logger.log('debug', `API.getCareGuideHelperTag(${id})`);
    return await this.GET(this._getUri(`/care-guide-helper-tag/${id}`));
  }

  // POST /care-guide-helper-tags
  async postCareGuideHelperTags(payload) {
    Logger.log('debug', `API.postCareGuideHelperTags(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-helper-tags`), payload);
  }

  // PUT /care-guide-helper-tag/{id}
  async putCareGuideHelperTag(id, payload) {
    Logger.log('debug', `API.putCareGuideHelperTag(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-helper-tag/${id}`),
      payload
    );
  }

  // DELETE /care-guide-helper-tag/{id}
  async deleteCareGuideHelperTag(id) {
    Logger.log('debug', `API.deleteCareGuideHelperTag(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-helper-tag/${id}`));
  }

  // GET /care-guide-helper-tags/csv
  async getCareGuideHelperTagsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideHelperTagsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-helper-tags/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide/{id}/members
  async getCareGuideMembers(filter = null) {
    Logger.log('debug', `API.getCareGuideMembers(%j)`, filter);
    return await this.GET(
      this._getUri(QueryString.append(`/care-guides/members`, { ...filter }))
    );
  }

  // GET /care-guide-invitations
  async getCareGuideInvitations(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideInvitations(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-invitations/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-invitation/{id}
  async getCareGuideInvitation(id) {
    Logger.log('debug', `API.getCareGuideInvitation(${id})`);
    return await this.GET(this._getUri(`/care-guide-invitation/${id}`));
  }

  // POST /care-guide-invitations
  async postCareGuideInvitations(payload) {
    Logger.log('debug', `API.postCareGuideInvitations(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-invitations`), payload);
  }

  // PUT /care-guide-invitation/{id}
  async putCareGuideInvitation(id, payload) {
    Logger.log('debug', `API.putCareGuideInvitation(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-invitation/${id}`),
      payload
    );
  }

  // DELETE /care-guide-invitation/{id}
  async deleteCareGuideInvitation(id) {
    Logger.log('debug', `API.deleteCareGuideInvitation(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-invitation/${id}`));
  }

  // GET /care-guide-invitations/csv
  async getCareGuideInvitationsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideInvitationsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-invitations/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /journal-posts
  async getJournalPosts(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getJournalPosts(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-posts/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-post/{id}
  async getJournalPost(id) {
    Logger.log('debug', `API.getJournalPost(${id})`);
    return await this.GET(this._getUri(`/journal-post/${id}`));
  }

  // POST /journal-posts
  async postJournalPosts(payload) {
    Logger.log('debug', `API.postJournalPosts(%j)`, payload);
    return await this.POST(this._getUri(`/journal-posts`), payload);
  }

  // PUT /journal-post/{id}
  async putJournalPost(id, payload) {
    Logger.log('debug', `API.putJournalPost(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/journal-post/${id}`), payload);
  }

  // DELETE /journal-post/{id}
  async deleteJournalPost(id) {
    Logger.log('debug', `API.deleteJournalPost(${id})`);
    return await this.DELETE(this._getUri(`/journal-post/${id}`));
  }

  // GET /journal-posts/csv
  async getJournalPostsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getJournalPostsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/journal-posts/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /journal-replies
  async getJournalReplies(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getJournalReplies(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-replies/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-reply/{id}
  async getJournalReply(id) {
    Logger.log('debug', `API.getJournalReply(${id})`);
    return await this.GET(this._getUri(`/journal-reply/${id}`));
  }

  // POST /journal-replies
  async postJournalReplies(payload) {
    Logger.log('debug', `API.postJournalReplies(%j)`, payload);
    return await this.POST(this._getUri(`/journal-replies`), payload);
  }

  // PUT /journal-reply/{id}
  async putJournalReply(id, payload) {
    Logger.log('debug', `API.putJournalReply(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/journal-reply/${id}`), payload);
  }

  // DELETE /journal-reply/{id}
  async deleteJournalReply(id) {
    Logger.log('debug', `API.deleteJournalReply(${id})`);
    return await this.DELETE(this._getUri(`/journal-reply/${id}`));
  }

  // GET /journal-replies/csv
  async getJournalRepliesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getJournalRepliesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/journal-replies/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /journal-tags
  async getJournalTags(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getJournalTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-tag/{id}
  async getJournalTag(id) {
    Logger.log('debug', `API.getJournalTag(${id})`);
    return await this.GET(this._getUri(`/journal-tag/${id}`));
  }

  // POST /journal-tags
  async postJournalTags(payload) {
    Logger.log('debug', `API.postJournalTags(%j)`, payload);
    return await this.POST(this._getUri(`/journal-tags`), payload);
  }

  // PUT /journal-tag/{id}
  async putJournalTag(id, payload) {
    Logger.log('debug', `API.putJournalTag(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/journal-tag/${id}`), payload);
  }

  // DELETE /journal-tag/{id}
  async deleteJournalTag(id) {
    Logger.log('debug', `API.deleteJournalTag(${id})`);
    return await this.DELETE(this._getUri(`/journal-tag/${id}`));
  }

  // GET /journal-tags/csv
  async getJournalTagsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getJournalTagsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/journal-tags/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /messages
  async getMessages(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMessages(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/messages/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /message/{id}
  async getMessage(id) {
    Logger.log('debug', `API.getMessage(${id})`);
    return await this.GET(this._getUri(`/message/${id}`));
  }

  // POST /messages
  async postMessages(payload) {
    Logger.log('debug', `API.postMessages(%j)`, payload);
    return await this.POST(this._getUri(`/messages`), payload);
  }

  // PUT /message/{id}
  async putMessage(id, payload) {
    Logger.log('debug', `API.putMessage(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/message/${id}`), payload);
  }

  // DELETE /message/{id}
  async deleteMessage(id) {
    Logger.log('debug', `API.deleteMessage(${id})`);
    return await this.DELETE(this._getUri(`/message/${id}`));
  }

  // GET /messages/csv
  async getMessagesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getMessagesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/messages/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /calendar-events
  async getCalendarEvents(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCalendarEvents(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/calendar-events/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /calendar-event/{id}
  async getCalendarEvent(id) {
    Logger.log('debug', `API.getCalendarEvent(${id})`);
    return await this.GET(this._getUri(`/calendar-event/${id}`));
  }

  // POST /calendar-events
  async postCalendarEvents(payload) {
    Logger.log('debug', `API.postCalendarEvents(%j)`, payload);
    return await this.POST(this._getUri(`/calendar-events`), payload);
  }

  // PUT /calendar-event/{id}
  async putCalendarEvent(id, payload) {
    Logger.log('debug', `API.putCalendarEvent(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/calendar-event/${id}`), payload);
  }

  // DELETE /calendar-event/{id}
  async deleteCalendarEvent(id) {
    Logger.log('debug', `API.deleteCalendarEvent(${id})`);
    return await this.DELETE(this._getUri(`/calendar-event/${id}`));
  }

  // GET /calendar-events/csv
  async getCalendarEventsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCalendarEventsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/calendar-events/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /calendar-event-exceptions
  async getCalendarEventExceptions(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCalendarEventExceptions(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/calendar-event-exceptions/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /calendar-event-exception/{id}
  async getCalendarEventException(id) {
    Logger.log('debug', `API.getCalendarEventException(${id})`);
    return await this.GET(this._getUri(`/calendar-event-exception/${id}`));
  }

  // POST /calendar-event-exceptions
  async postCalendarEventExceptions(payload) {
    Logger.log('debug', `API.postCalendarEventExceptions(%j)`, payload);
    return await this.POST(this._getUri(`/calendar-event-exceptions`), payload);
  }

  // PUT /calendar-event-exception/{id}
  async putCalendarEventException(id, payload) {
    Logger.log('debug', `API.putCalendarEventException(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/calendar-event-exception/${id}`),
      payload
    );
  }

  // DELETE /calendar-event-exception/{id}
  async deleteCalendarEventException(id) {
    Logger.log('debug', `API.deleteCalendarEventException(${id})`);
    return await this.DELETE(this._getUri(`/calendar-event-exception/${id}`));
  }

  // GET /calendar-event-exceptions/csv
  async getCalendarEventExceptionsCSV(order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCalendarEventExceptionsCSV(${order}, ${filter})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/calendar-event-exceptions/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /calendar-events-admin-participants/${calendarEventId}
  async getCalendarEventParticipants(calendarEventId, page = 1, limit = 10) {
    Logger.log(
      'debug',
      `API.getCalendarEventParticipants(${calendarEventId}, ${page})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/calendar-events-participants/${calendarEventId}/${parseInt(
            page
          )}/${parseInt(limit)}`
        )
      )
    );
  }

  // POST /care-guide/{careGuideId}/calendar/events
  async postCalendarEventParticipant(eventId, payload) {
    Logger.log(
      'debug',
      `API.postCalendarEventParticipant(${eventId}, %j)`,
      payload
    );
    return await this.POST(
      this._getUri(`/calendar-events/${eventId}/participant`),
      payload
    );
  }

  // PUT /calendar-events-admin-participant/{participantId}
  async putCalendarEventParticipant(participantId, payload) {
    Logger.log(
      'debug',
      `API.putCalendarEventParticipant(${participantId}, ###)`
    );
    return await this.PUT(
      this._getUri(`/calendar-events-participant/${participantId}`),
      payload
    );
  }

  // DELETE /calendar-events-admin-participant/{CalendarEventParticipantId}
  async deleteCalendarEventParticipant(calendarEventParticipantId, payload) {
    Logger.log(
      'debug',
      `API.deleteCalendarEventParticipant(${calendarEventParticipantId})`
    );
    return await this.DELETE(
      this._getUri(
        `/calendar-events-participant/${calendarEventParticipantId}`
      ),
      payload
    );
  }

  // GET /resources
  async getResources(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getResources(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/resources/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /resource/{id}
  async getResource(id) {
    Logger.log('debug', `API.getResource(${id})`);
    return await this.GET(this._getUri(`/resource/${id}`));
  }

  // POST /resources
  async postResources(payload) {
    Logger.log('debug', `API.postResources(%j)`, payload);
    return await this.POST(this._getUri(`/resources`), payload);
  }

  // PUT /resource/{id}
  async putResource(id, payload) {
    Logger.log('debug', `API.putResource(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/resource/${id}`), payload);
  }

  // DELETE /resource/{id}
  async deleteResource(id) {
    Logger.log('debug', `API.deleteResource(${id})`);
    return await this.DELETE(this._getUri(`/resource/${id}`));
  }

  // GET /resources/csv
  async getResourcesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getResourcesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/resources/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /resource-tags
  async getResourceTags(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getResourceTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /resource-tag/{id}
  async getResourceTag(id) {
    Logger.log('debug', `API.getResourceTag(${id})`);
    return await this.GET(this._getUri(`/resource-tag/${id}`));
  }

  // POST /resource-tags
  async postResourceTags(payload) {
    Logger.log('debug', `API.postResourceTags(%j)`, payload);
    return await this.POST(this._getUri(`/resource-tags`), payload);
  }

  // PUT /resource-tag/{id}
  async putResourceTag(id, payload) {
    Logger.log('debug', `API.putResourceTag(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/resource-tag/${id}`), payload);
  }

  // DELETE /resource-tag/{id}
  async deleteResourceTag(id) {
    Logger.log('debug', `API.deleteResourceTag(${id})`);
    return await this.DELETE(this._getUri(`/resource-tag/${id}`));
  }

  // GET /resource-tags/csv
  async getResourceTagssCSV(order = null, filter = null) {
    Logger.log('debug', `API.getResourceTagssCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/resource-tags/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /resource-service-tags
  async getResourceServiceTags(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getResourceServiceTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-service-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /resource-service-tag/{id}
  async getResourceServiceTag(id) {
    Logger.log('debug', `API.getResourceServiceTag(${id})`);
    return await this.GET(this._getUri(`/resource-service-tag/${id}`));
  }

  // POST /resource-service-tags
  async postResourceServiceTags(payload) {
    Logger.log('debug', `API.postResourceServiceTags(%j)`, payload);
    return await this.POST(this._getUri(`/resource-service-tags`), payload);
  }

  // PUT /resource-service-tag/{id}
  async putResourceServiceTag(id, payload) {
    Logger.log('debug', `API.putResourceServiceTag(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/resource-service-tag/${id}`), payload);
  }

  // DELETE /resource-service-tag/{id}
  async deleteResourceServiceTag(id) {
    Logger.log('debug', `API.deleteResourceServiceTag(${id})`);
    return await this.DELETE(this._getUri(`/resource-service-tag/${id}`));
  }

  // GET /resource-service-tags/csv
  async getResourceServiceTagssCSV(order = null, filter = null) {
    Logger.log('debug', `API.getResourceServiceTagssCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/resource-service-tags/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /resource-notes
  async getResourceNotes(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getResourceNotes(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/resource-notes/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /resource-note/{id}
  async getResourceNote(id) {
    Logger.log('debug', `API.getResourceNote(${id})`);
    return await this.GET(this._getUri(`/resource-note/${id}`));
  }

  // POST /resource-notes
  async postResourceNotes(payload) {
    Logger.log('debug', `API.postResourceNotes(%j)`, payload);
    return await this.POST(this._getUri(`/resource-notes`), payload);
  }

  // PUT /resource-note/{id}
  async putResourceNote(id, payload) {
    Logger.log('debug', `API.putResourceNote(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/resource-note/${id}`), payload);
  }

  // DELETE /resource-note/{id}
  async deleteResourceNote(id) {
    Logger.log('debug', `API.deleteResourceNote(${id})`);
    return await this.DELETE(this._getUri(`/resource-note/${id}`));
  }

  // GET /resource-notes/csv
  async getResourceNotesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getResourceNotesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/resource-notes/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /registration-codes
  async getRegistrationCodes(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getRegistrationCodes(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/registration-codes/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /registration-code/{id}
  async getRegistrationCode(id) {
    Logger.log('debug', `API.getRegistrationCode(${id})`);
    return await this.GET(this._getUri(`/registration-code/${id}`));
  }

  // POST /registration-codes
  async postRegistrationCodes(payload) {
    Logger.log('debug', `API.postRegistrationCodes(%j)`, payload);
    return await this.POST(this._getUri(`/registration-codes`), payload);
  }

  // PUT /registration-code/{id}
  async putRegistrationCode(id, payload) {
    Logger.log('debug', `API.putRegistrationCode(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/registration-code/${id}`), payload);
  }

  // DELETE /registration-code/{id}
  async deleteRegistrationCode(id) {
    Logger.log('debug', `API.deleteRegistrationCode(${id})`);
    return await this.DELETE(this._getUri(`/registration-code/${id}`));
  }

  // GET /registration-codes/csv
  async getRegistrationCodesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getRegistrationCodesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/registration-codes/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /media
  async getMedia(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getMedia(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/media/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /medium/{id}
  async getMedium(id) {
    Logger.log('debug', `API.getMedium(${id})`);
    return await this.GET(this._getUri(`/medium/${id}`));
  }

  // POST /media
  async postMedia(payload) {
    Logger.log('debug', `API.postMedia(%j)`, payload);
    return await this.POST(this._getUri(`/media`), payload);
  }

  // PUT /medium/{id}
  async putMedium(id, payload) {
    Logger.log('debug', `API.putMedium(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/medium/${id}`), payload);
  }

  // DELETE /medium/{id}
  async deleteMedium(id) {
    Logger.log('debug', `API.deleteMedium(${id})`);
    return await this.DELETE(this._getUri(`/medium/${id}`));
  }

  // GET /media/csv
  async getMediaCSV(order = null, filter = null) {
    Logger.log('debug', `API.getMediaCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/media/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide/{care_guide_id}/media
  async getCareGuideMedia(
    careGuideId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideMedia(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide/${careGuideId}/media/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /timezones
  async getTimezones() {
    Logger.log('debug', `API.getTimezones()`);
    return await this.GET(this._getUri(QueryString.append(`/timezones`)));
  }

  // GET /registrations
  async getRegistrations(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getRegistrations(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/registrations/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /registration/{id}
  async getRegistration(id) {
    Logger.log('debug', `API.getRegistration(${id})`);
    return await this.GET(this._getUri(`/registration/${id}`));
  }

  // GET /password_resets
  async getPasswordResets(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPasswordResets(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/password_resets/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /imports/csv/upload_url
  async getImportsCSVUploadURL() {
    Logger.log('debug', `API.getImportsCSVUploadURL()`);
    return await this.GET(this._getUri(`/imports/csv/upload_url`));
  }

  // POST /imports/csv/message
  async postImportsCSVMessage(payload) {
    Logger.log('debug', `API.postImportsCSVMessage(%j)`, payload);
    return await this.POST(this._getUri(`/imports/csv/message`), payload);
  }

  // GET /imports/jobs
  async getImportJobs(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getImportJobs(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/imports/jobs/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /imports/job/{id}
  async getImportJob(id) {
    Logger.log('debug', `API.getImportJob(${id})`);
    return await this.GET(this._getUri(`/imports/job/${id}`));
  }

  // GET /partnerships
  async getPartnerships(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPartnerships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnerships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership/{id}
  async getPartnership(id) {
    Logger.log('debug', `API.getPartnership(${id})`);
    return await this.GET(this._getUri(`/partnership/${id}`));
  }

  // PUT /partnership/{id}
  async putPartnership(id, payload) {
    Logger.log('debug', `API.putPartnership(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/partnership/${id}`), payload);
  }

  // POST /partnership
  async postPartnership(payload) {
    Logger.log('debug', `API.postPartnership(%j)`, payload);
    return await this.POST(this._getUri(`/partnership`), payload);
  }

  // DELETE /partnership/{id}
  async deletePartnership(id) {
    Logger.log('debug', `API.deletePartnership(${id})`);
    return await this.DELETE(this._getUri(`/partnership/${id}`));
  }

  // GET /partnerships/csv
  async getPartnershipsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getPartnershipsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/partnerships/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /user-profiles-partnerships
  async getUserProfilesPartnerships(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getUserProfilesPartnerships(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/user-profiles-partnerships/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /user-profiles-partnership/{id}
  async getUserProfilesPartnership(id) {
    Logger.log('debug', `API.getUserProfilesPartnership(${id})`);
    return await this.GET(this._getUri(`/user-profiles-partnership/${id}`));
  }

  // POST /user-profiles-partnerships
  async postUserProfilesPartnerships(payload) {
    Logger.log('debug', `API.postUserProfilesPartnerships(%j)`, payload);
    return await this.POST(
      this._getUri(`/user-profiles-partnerships`),
      payload
    );
  }

  // PUT /user-profiles-partnership/{id}
  async putUserProfilesPartnership(id, payload) {
    Logger.log('debug', `API.putUserProfilesPartnership(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/user-profiles-partnership/${id}`),
      payload
    );
  }

  // DELETE /user-profiles-partnership/{id}
  async deleteUserProfilesPartnership(id) {
    Logger.log('debug', `API.deleteUserProfilesPartnership(${id})`);
    return await this.DELETE(this._getUri(`/user-profiles-partnership/${id}`));
  }

  // GET /user-profiles-partnerships/csv
  async getUserProfilesPartnershipsCSV(order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getUserProfilesPartnershipsCSV(${order}, ${filter})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/user-profiles-partnerships/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /user_profiles
  async getUserProfiles(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getUserProfiles(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/user_profiles/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-partner-tags
  async getJournalPartnerTags(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getJournalPartnerTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-partner-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /journal-partner-tag/{id}
  async getJournalPartnerTag(id) {
    Logger.log('debug', `API.getJournalPartnerTag(${id})`);
    return await this.GET(this._getUri(`/journal-partner-tag/${id}`));
  }

  // POST /journal-partner-tag
  async postJournalPartnerTags(payload) {
    Logger.log('debug', `API.postJournalPartnerTags(%j)`, payload);
    return await this.POST(this._getUri(`/journal-partner-tag`), payload);
  }

  // PUT /journal-partner-tag/{id}
  async putJournalPartnerTag(id, payload) {
    Logger.log('debug', `API.putJournalPartnerTag(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/journal-partner-tag/${id}`), payload);
  }

  // DELETE /journal-partner-tag/{id}
  async deleteJournalPartnerTag(id) {
    Logger.log('debug', `API.deleteJournalPartnerTag(${id})`);
    return await this.DELETE(this._getUri(`/journal-partner-tag/${id}`));
  }

  // GET /journal-partner-tags/csv
  async getJournalPartnerTagsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getJournalPartnerTagsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/journal-partner-tags/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /journal-partner-tag/{id}/partner-tags
  async getJournalPartnerTagCareGuide(
    id,
    page = 1,
    limit = 150,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getJournalPartnerTagCareGuide(${id}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/journal-partner-tag/${id}/partner-tags/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership/{care_guide_id}/media
  async getPartnershipMedia(
    partnershipId,
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getPartnershipMedia(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership/${partnershipId}/media/${parseInt(page)}/${parseInt(
            limit
          )}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /emails
  async getEmails(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getEmails(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/emails/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /email/{id}
  async getEmail(id) {
    Logger.log('debug', `API.getEmail(${id})`);
    return await this.GET(this._getUri(`/email/${id}`));
  }

  // PUT /email/{id}
  async putEmail(id, payload) {
    Logger.log('debug', `API.putEmail(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/email/${id}`), payload);
  }

  // DELETE /email/{id}
  async deleteEmail(id) {
    Logger.log('debug', `API.deleteEmail(${id})`);
    return await this.DELETE(this._getUri(`/email/${id}`));
  }

  // GET /emails/csv
  async getEmailsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getEmailsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/emails/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-partner-tags
  async getCareGuidePartnerTags(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuidePartnerTags(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-partner-tags/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-partner-tag/{id}
  async getCareGuidePartnerTag(id) {
    Logger.log('debug', `API.getCareGuidePartnerTag(${id})`);
    return await this.GET(this._getUri(`/care-guide-partner-tag/${id}`));
  }

  // POST /care-guide-partner-tag
  async postCareGuidePartnerTags(payload) {
    Logger.log('debug', `API.postCareGuidePartnerTags(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-partner-tag`), payload);
  }

  // PUT /care-guide-partner-tag/{id}
  async putCareGuidePartnerTag(id, payload) {
    Logger.log('debug', `API.putCareGuidePartnerTag(${id}, %j)`, payload);
    return await this.PUT(
      this._getUri(`/care-guide-partner-tag/${id}`),
      payload
    );
  }

  // DELETE /care-guide-partner-tag/{id}
  async deleteCareGuidePartnerTag(id) {
    Logger.log('debug', `API.deleteCareGuidePartnerTag(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-partner-tag/${id}`));
  }

  // GET /care-guide-partner-tags/csv
  async getCareGuidePartnerTagsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuidePartnerTagsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-partner-tags/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /admin-partner-classes
  async getAdminPartnerClasses(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getAdminPartnerClasses(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/admin-partner-classes/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /admin-partner-class/{id}
  async getAdminPartnerClass(id) {
    Logger.log('debug', `API.getAdminPartnerClass(${id})`);
    return await this.GET(this._getUri(`/admin-partner-class/${id}`));
  }

  // POST /admin-partner-class
  async postAdminPartnerClasses(payload) {
    Logger.log('debug', `API.postAdminPartnerClasses(%j)`, payload);
    return await this.POST(this._getUri(`/admin-partner-class`), payload);
  }

  // PUT /admin-partner-class/{id}
  async putAdminPartnerClass(id, payload) {
    Logger.log('debug', `API.putAdminPartnerClass(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/admin-partner-class/${id}`), payload);
  }

  // DELETE /admin-partner-class/{id}
  async deleteAdminPartnerClass(id) {
    Logger.log('debug', `API.deleteAdminPartnerClass(${id})`);
    return await this.DELETE(this._getUri(`/admin-partner-class/${id}`));
  }

  // GET /admin-partner-classes/csv
  async getAdminPartnerClassesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getAdminPartnerClassesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/admin-partner-classes/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /partnership/{id}/admin-partner-classes
  async getAdminPartnerClassesPartnership(partnershipId = null) {
    Logger.log(
      'debug',
      `API.getAdminPartnerClassesPartnership(${partnershipId})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership/${partnershipId}/admin-partner-classes`
        )
      )
    );
  }

  // GET /care-guide-notes
  async getCareGuideNotes(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getCareGuideNotes(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-notes/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-note/{id}
  async getCareGuideNote(id) {
    Logger.log('debug', `API.getCareGuideNote(${id})`);
    return await this.GET(this._getUri(`/care-guide-note/${id}`));
  }

  // POST /care-guide-note
  async postCareGuideNotes(payload) {
    Logger.log('debug', `API.postCareGuideNotes(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-note`), payload);
  }

  // PUT /care-guide-note/{id}
  async putCareGuideNote(id, payload) {
    Logger.log('debug', `API.putCareGuideNote(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-note/${id}`), payload);
  }

  // DELETE /care-guide-note/{id}
  async deleteCareGuideNote(id) {
    Logger.log('debug', `API.deleteCareGuideNote(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-note/${id}`));
  }

  // GET /care-guide-notes/csv
  async getCareGuideNotesCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideNotesCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-notes/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /care-guide-documents
  async getCareGuideDocuments(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getCareGuideDocuments(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/care-guide-documents/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /care-guide-document/{id}
  async getCareGuideDocument(id) {
    Logger.log('debug', `API.getCareGuideDocument(${id})`);
    return await this.GET(this._getUri(`/care-guide-document/${id}`));
  }

  // POST /care-guide-document
  async postCareGuideDocuments(payload) {
    Logger.log('debug', `API.postCareGuideDocuments(%j)`, payload);
    return await this.POST(this._getUri(`/care-guide-document`), payload);
  }

  // PUT /care-guide-document/{id}
  async putCareGuideDocument(id, payload) {
    Logger.log('debug', `API.putCareGuideDocument(${id}, %j)`, payload);
    return await this.PUT(this._getUri(`/care-guide-document/${id}`), payload);
  }

  // DELETE /care-guide-document/{id}
  async deleteCareGuideDocument(id) {
    Logger.log('debug', `API.deleteCareGuideDocument(${id})`);
    return await this.DELETE(this._getUri(`/care-guide-document/${id}`));
  }

  // GET /care-guide-documents/csv
  async getCareGuideDocumentsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getCareGuideDocumentsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/care-guide-documents/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // GET /partnership_form_answers
  async getPartnershipFormAnswers(
    page = 1,
    limit = 10,
    order = null,
    filter = null
  ) {
    Logger.log(
      'debug',
      `API.getPartnershipFormAnswers(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/partnership_form_answers/${parseInt(page)}/${parseInt(limit)}`,
          { order_by: order, ...filter }
        )
      )
    );
  }

  // GET /partnership_form_answer/{id}
  async getPartnershipFormAnswer(id) {
    Logger.log('debug', `API.getPartnershipFormAnswer(${id})`);
    return await this.GET(this._getUri(`/partnership_form_answer/${id}`));
  }

  // GET /partnership_form_answer/csv
  async getPartnershipFormAnswersCSV(order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getPartnershipFormAnswersCSV(${order}, ${filter})`
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/partnership_form_answer/csv`, {
          order_by: order,
          ...filter,
        })
      ),
      true,
      'blob'
    );
  }

  // DELETE /partnership_form_answer/{id}
  async deletePartnershipFormAnswer(id) {
    Logger.log('debug', `API.deletePartnershipFormAnswer(${id})`);
    return await this.DELETE(this._getUri(`/partnership_form_answer/${id}`));
  }

  // GET /import-tasks
  async getImportTasks(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getImportTasks(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(
          `/import-tasks/${parseInt(page)}/${parseInt(limit)}`,
          {
            order_by: order,
            ...filter,
          }
        )
      )
    );
  }

  // GET /import-task/{id}
  async getImportTask(id) {
    Logger.log('debug', `API.getImportTask(${id})`);
    return await this.GET(this._getUri(`/import-task/${id}`));
  }

  // GET /alerts
  async getAlerts(page = 1, limit = 10, order = null, filter = null) {
    Logger.log(
      'debug',
      `API.getAlerts(${page}, ${limit}, ${order}, %j)`,
      filter
    );
    return await this.GET(
      this._getUri(
        QueryString.append(`/alerts/${parseInt(page)}/${parseInt(limit)}`, {
          order_by: order,
          ...filter,
        })
      )
    );
  }

  // GET /alert/{id}
  async getAlert(id) {
    Logger.log('debug', `API.getAlert(${id})`);
    return await this.GET(this._getUri(`/alert/${id}`));
  }

  // GET /alerts/csv
  async getAlertsCSV(order = null, filter = null) {
    Logger.log('debug', `API.getAlertsCSV(${order}, ${filter})`);
    return await this.GET(
      this._getUri(
        QueryString.append(`/alerts/csv`, { order_by: order, ...filter })
      ),
      true,
      'blob'
    );
  }

  // External API Calls

  // GET https://maps.googleapis.com/maps/api/geocode/json?key={key}&address={location}
  async getGoogleMapsAPIGeocode(location) {
    const apiKey = Config.get('GOOGLE_MAPS_API_KEY');
    return await this.axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${encodeURIComponent(
          location
        )}`
      )
      .then(async function (response) {
        Logger.log('verbose', `API Response:`, response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });
  }

  // PUT file to S3 bucket
  async putS3Upload(path, file) {
    Logger.log('debug', `API.putS3Upload(${path}, ###)`);

    const requestHeaders = {
      headers: {
        'Content-Type': file.type,
      },
    };

    return await this.axios
      .put(path, file, requestHeaders)
      .then(async function (response) {
        Logger.log('verbose', `S3 Response:`, response);
        return Map(response);
      })
      .catch(function (error) {
        Logger.log('error', `${error}`);
        return Map({ status: 500, data: { error: `${error}` } });
      });
  }
}

const api = new API(Config, axios);

export default api;

Logger.log('debug', `API loaded.`);
